<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">人力资源</div>
      <div class="h2">人才计划 助力企业经济腾飞</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/rlzy' }">人力资源</el-breadcrumb-item>
        <el-breadcrumb-item  :to="{ path: '/rlzylist' }">政府人才名录</el-breadcrumb-item>
        <el-breadcrumb-item >详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="compxq">
        <div  class="flex">
          <div class="images">
            <el-image style="height: 300px" :src="datas.avatar"></el-image>
            <div>
              <fujian  :files="datas.resumeFile"></fujian>
            </div>
            <el-button class="collect" @click="collect" :class="{ active: isCollect}" :disabled="disabled" type="primary"><i class="el-icon-star-off mr2"></i>{{ isCollect ? '已收藏' : '收藏' }}</el-button>
          </div>
          <div class="comptitle flex-1">
            <div class="datitle"><div class="name">姓名：{{datas.name}}</div>基本资料</div>
            <el-descriptions class="margin-top"  :column="2" >
              <el-descriptions-item label="性别">{{datas.sex}}</el-descriptions-item>
              <el-descriptions-item label="学历">{{datas.degree}}</el-descriptions-item>
              <el-descriptions-item label="手机号">{{datas.phone}}</el-descriptions-item>
              <el-descriptions-item label="邮箱">{{datas.email}}</el-descriptions-item>
              <el-descriptions-item label="所属区域">{{datas.province}}{{datas.city}}{{datas.area}}</el-descriptions-item>
            </el-descriptions>
            <div class="datitle">职业信息</div>
            <el-descriptions class="margin-top"  :column="2" >
              <el-descriptions-item label="应聘职位">{{datas.jobPosition}}</el-descriptions-item>
              <el-descriptions-item label="期望薪资">{{datas.jobPosition}}</el-descriptions-item>
              <el-descriptions-item label="职位类别">{{datas.major}}</el-descriptions-item>
              <el-descriptions-item label="行业类别">{{datas.industry}}</el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
      <div style="background: #fff;padding: 25px;border-radius: 4px">
        <div class="datitle">其他信息</div>
        <div class="compdesc ">
          <div class="jianjie">个人简介:</div>
          <div style="padding: 25px"  v-html="datas.content"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { fiveyears } from '../../utils/index'
import {policyindex, policypage} from "../../request/moudle/zcfw";
import {enterprisedetail, parklist} from "../../request/moudle/park";
import fujian from "../common/fujian.vue";
import {humanpersonneldetail, humanCollect, humanCancelCollect, humanIsCollected} from "../../request/moudle/rlzy";
export default {
  name: "zchj",
  components: {fujian},
  data(){
    return {
      datas:'',
      isCollect: false,
      disabled: true,
      userInfo:  this.common.getSessionStorage('userInfo'),
    }
  },
  created() {
    this.loadxq();
    if(this.userInfo){
       this.humanIsCollect();
       this.disabled = false;
    }else{
       this.disabled = true;
    }
  },
  methods:{
    goBack() {
     this.$router.go(-1)
    },
    loadxq(){
      this.$api.rlzy.humanpersonneldetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.datas = res.data;
        this.datas.resumeFile = "人才简历下载";
      })
    },

    //是否收藏文章
    humanIsCollect(){
      let obj = {};
      obj.articleId = this.$route.query.id;
      obj.articleType = 'personnel'
      this.$api.rlzy.humanIsCollected(obj).then((res)=>{
        if(res.success){
          this.isCollect = res.data;
        }
      })
    },

    //收藏/取消功能
    collect(){
      if(this.isCollect){
         //取消收藏
         this.$api.rlzy.humanCancelCollect({
          articleId:this.$route.query.id,
          articleType: 'personnel',
        }).then((res)=>{
          console.log(res);
          this.$message.success('取消收藏成功！')
        })
      }else{
        //收藏
        this.$api.rlzy.humanCollect({
          articleId:this.$route.query.id,
          articleType: 'personnel',
        }).then((res)=>{
          console.log(res);
          this.$message.success('收藏成功！')
        })
      }
      this.isCollect = !this.isCollect;
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-descriptions-item__label{
  min-width: 120px;
}
.zchjs{
  background: #F5F5F5;
}
.datitle{
  text-align: right;
  border-bottom: 1px solid #DCDFE6;
  padding: 15px 25px;
  margin: 15px 0;
  .name{
    font-size: 16px;
    font-weight: bolder;
    float: left;
  }
}
.header{
  height: 220px;
  background: url("../../assets/banner/yqgl.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 0px auto;
  padding: 25px;
  /deep/ .el-col{
    padding: 15px ;
  }
  .compdesc{
    font-size: #3E3E3E;
    text-align: left;
    margin-top: 10px;
  }
  .margin-top{
    padding:10px 25px ;
  }
  .comptitle{
    background: #fff;
    border-radius: 4px;

  }
  .jianjie{
    font-size: 16px;
    font-weight: bolder;
    margin: 25px 0;
  }
  .compxq{
    margin: 25px 0;
    .images{
      width: 250px;
      padding: 38px;
      background: #fff;
      margin-right: 15px;
      border-radius: 4px;
    }
  }
  .collect {
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
    margin-left: 80px;
    .mr2 {
      margin-right: 2px;
    }
  }
}
.active{
  color: #fff;
}
</style>